import { createStore } from 'vuex'

export default createStore({
  state: {
    Language : localStorage.getItem('language')
  },
  getters: {
    getLanguage(state){
      return state.Language
    }
  },
  mutations: {
    updateLanguage(state, payload){
      localStorage.setItem('language',payload.language)
      state.Language = payload.language
      // console.log(payload.language);
    }
  },
  actions: {
    updateLanguage({commit},payload){
      commit('updateLanguage',payload)
      // console.log(payload);
    }
  },
  modules: {
  }
})
