<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-dark pe-5 fixed-top" :class="{ grey: isDark }">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img src="../assets/logo-bpe.png" alt="" width="50" class="d-inline-block align-top" />
          <!-- PT. Blitar Putra Energi  -->
          {{ $t("name") }}
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample04"
          aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarsExample04">
          <ul class="navbar-nav ms-auto mb-2 mb-md-0 pe-5">
            <li class="nav-item dropdown me-3">
              <a class="nav-link dropdown-toggle" href="#" id="dropdown04" data-bs-toggle="dropdown"
                aria-expanded="false">{{$t('routes.info.title')}}
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdown04">
                <li>
                  <router-link class="dropdown-item" to="/">{{ $t('routes.info.more.sec1') }}</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/vision-mission">{{ $t('routes.info.more.sec2') }}
                  </router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/organizational-structure">{{ $t('routes.info.more.sec3') }}
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="nav-item dropdown me-3 " exact exact-active-class="active">
              <a class="nav-link dropdown-toggle" href="#" id="dropdown04" data-bs-toggle="dropdown"
                aria-expanded="false">{{ $t('routes.business.title') }}</a>
              <ul class="dropdown-menu" aria-labelledby="dropdown04">
                <li>
                  <router-link class="dropdown-item" to="/green-industrial-integrated-plant-area">
                    {{ $t('routes.business.more.sec1') }}
                  </router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/dokumentasi">{{ $t('routes.business.more.sec2') }}
                  </router-link>
                </li>

              </ul>
            </li>
            <li class="nav-item dropdown me-3">
              <router-link class="nav-link dropdown-toggle" to="#" id="dropdown04" data-bs-toggle="dropdown"
                aria-expanded="false">{{ $t('routes.investors.title') }}</router-link>
              <!-- <ul class="dropdown-menu" aria-labelledby="dropdown04">
                <li>
                  <router-link class="dropdown-item" to="/green-industrial-integrated-plant-area">
                    {{ $t('routes.investors.more.sec1') }}
                  </router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/green-industrial-integrated-plant-area">
                    {{ $t('routes.investors.more.sec2') }}
                  </router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/berita-investor">
                    {{ $t('routes.investors.more.sec3') }}
                  </router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/kontak-hubung-investor">
                    {{ $t('routes.investors.more.sec4') }}
                  </router-link>
                </li>
              </ul> -->

              <ul class="dropdown-menu" aria-labelledby="dropdown04">
                <li>
                  <router-link class="dropdown-item" to="/relasi-investor">
                    {{ $t('routes.investors.more.sec1') }}
                  </router-link>
                </li>
                <li>
                  
                  <a class="dropdown-item" href="/relasi-investor#whyinvestinblitarputraenergi">
                    {{ $t('routes.investors.more.sec2') }}
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="/relasi-investor#latestnews">
                    {{ $t('routes.investors.more.sec3') }}
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="/relasi-investor#kontakinvestor">
                    {{ $t('routes.investors.more.sec4') }}
                  </a>
                </li>
              </ul>


            </li>
            <li class="nav-item me-3">
              <router-link class="nav-link" aria-current="page" to="/contacts">{{ $t('routes.contact.title') }}
              </router-link>
            </li>
            <li class="nav-item me-3">

              <div class="input-group">
                <a class="nav-link" href="#" aria-expanded="false">{{ $t('routes.languages.title') }}</a>
                <!-- <select class="select-lang p-0" v-model="$i18n.locale" @change="changeLanguage"> -->
                <select class="select-lang p-0" v-model="$i18n.locale" @change="changeLanguage($i18n.locale)">
                  <option value="en">{{ $t('routes.languages.more.sec1') }}</option>
                  <option value="id">{{ $t('routes.languages.more.sec2') }}</option>
                </select>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>


  </div>
</template>
  
<script>
// import i18n from 'vue-i18n'
import store from '@/store';
import { mapActions } from 'vuex';
    export default {
        data(){
        return{
          isDark:false,
          language : null
        }
      },
      created () {
        window.addEventListener('scroll', this.handleScroll);
      },
      unmounted () {
        window.removeEventListener('scroll', this.handleScroll);
      },
      methods:{
        ...mapActions({
          changeLanguage:'updateLanguage'
        }),
        handleScroll (event) {
          const navBar = document.querySelector('.navbar');
          if(window.scrollY >= 56){
            this.isDark = true;
          }else if(window.scrollY < 56){
            this.isDark = false
          }
        },
        changeLanguage(obj){
          // console.log(obj);
          return this.$store.dispatch('updateLanguage',{'language' : obj});
          // localStorage.setItem('language',obj)
          this.language = obj
            // this.language = localStorage.setItem('language',obj)
            // console.log(localStorage.getItem('language'));
        },
        
      },
      
    }
</script>
<style>
    .grey{
        background-color: rgb(128, 128, 128);
    }
</style>