<template>
  <Navbar></Navbar>

  <router-view/>
  <Modal :visible="false" variant="success"></Modal>
</template>

  

<script>
import Navbar from './components/Header';
import Modal from './components/Modal.vue'

import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    Navbar, Modal
  },
  methods:{
    ...mapActions({
      setLanguage : 'updateLanguage'
  }),
  setLanguage(){
    return this.$store.dispatch('updateLanguage',{'language':'en'});
  }
  },
  mounted() {
    // localStorage.setItem('language','en')
    return this.setLanguage()

  },




}


</script>

<style>

</style>
